import React, { useContext } from 'react';
import { SignUpContext } from './context/signup';
import { SvgResidential, SvgCommercial, SvgRollOffs } from './svgs';
import Axios from 'axios';
import config from '../../services/config';
import { useStyles } from './elementStyles';

const Card1 = ({ step, stepIndex, goToNextStep }) => {
	const { stepAnswers, setAnswer, hauler } = useContext(SignUpContext);

	const answer = stepAnswers[stepIndex];
	const [availableTypes, setAvailableTypes] = React.useState([]);

	const { primaryColor, primaryTextColor } = hauler.theme;
	const { styles } = useStyles();

	// const primaryColor = '#6DB262';

	// const secondaryColor = 'blue';

	const themedClass = {
		color: 'black',
		lineHeight: '50px',
		marginBottom: '10px',
		borderRadius: '20px',
		border: '1px solid black',
	};

	const activeThemeClass = {
		...themedClass,
	};

	const themedClass_svg = {
		color: 'black',
		float: 'left',
		height: '50px',
	};

	const activeThemeClass_svg = {
		...themedClass_svg,
		color: primaryTextColor,
		float: 'left',
		height: '50px',
	};

	const s = {
		square_container: {
			position: 'relative',
			width: '30px',
			height: '30px',
			marginTop: '10px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
		},
		square_container_active: {
			position: 'relative',
			width: '30px',
			height: '30px',
			marginTop: '10px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
			background: primaryColor,
		},
	};

	React.useEffect(() => {
		const findTypes = () => {
			const url = config.apiBaseUrl;

			Axios.get(`${url}/companies.cfc?method=getAvailableTypes&i=${hauler.id}`)
				.then((e) => {
					if (e.status === 200) {
						return e.data;
					} else {
						// throw 'error';
					}
				})
				.then((d) => setAvailableTypes(d))
				.catch((e) => setAvailableTypes(['Residential', 'Commercial', 'Roll-Offs']));
		};

		findTypes();
	}, [hauler.id]);

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', height: '100%' }}>
					<span style={styles.title} className="card-title z-depth-1">
						{step.title}
					</span>
					<div className="valign-wrapper" style={{ height: '85%', width: '90%', maxWidth: '400px', padding: '20px 0', margin: '0 auto' }}>
						<div className="collection" style={{ width: '100%', margin: '0 auto', border: '0' }}>
							{availableTypes.indexOf('Residential') > -1 && (
								<a style={answer === 'Residential' ? activeThemeClass : themedClass} href="#!" className="collection-item left-align" onClick={() => setAnswer({ stepIndex, answer: 'Residential' })}>
									{/* <img style={{ float: 'left', height: '50px' }} src={img01} alt="" /> */}
									<span style={{ paddingRight: '20px' }}>
										<SvgResidential style={answer === 'Residential' ? activeThemeClass_svg : themedClass_svg} />
									</span>
									{'Residential'.toUpperCase()}
									<span style={answer === 'Residential' ? s.square_container_active : s.square_container} className="right selected-indicator"></span>
								</a>
							)}
							{availableTypes.indexOf('Commercial') > -1 && (
								<a style={answer === 'Commercial' ? activeThemeClass : themedClass} href="#!" className="collection-item left-align" onClick={() => setAnswer({ stepIndex, answer: 'Commercial' })}>
									{/* <img style={{ float: 'left', height: '50px' }} src={img01} alt="" /> */}
									<span style={{ paddingRight: '20px' }}>
										<SvgCommercial style={answer === 'Commercial' ? activeThemeClass_svg : themedClass_svg} />
									</span>
									{'Commercial'.toUpperCase()}
									<span style={answer === 'Commercial' ? s.square_container_active : s.square_container} className="right selected-indicator"></span>
								</a>
							)}
							{availableTypes.indexOf('Roll-Offs') > -1 && (
								<a style={answer === 'Roll-Offs' ? activeThemeClass : themedClass} href="#!" className="collection-item left-align" onClick={() => setAnswer({ stepIndex, answer: 'Roll-Offs' })}>
									<span style={{ paddingRight: '20px' }}>
										<SvgRollOffs style={answer === 'Roll-Offs' ? activeThemeClass_svg : themedClass_svg} />
									</span>
									{'Roll-Offs'.toUpperCase()}
									<span style={answer === 'Roll-Offs' ? s.square_container_active : s.square_container} className="right selected-indicator"></span>
								</a>
							)}
						</div>
					</div>
				</div>
				<div className="card-action">
					<button style={answer ? styles.button.enabled : styles.button.disabled} className="btn btn-small themedButton" onClick={() => goToNextStep(stepIndex)}>
						Next
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Card1;
