import React, { useEffect, useState } from 'react';
import customerService from '../../../services/customers';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loader';
import EditForm from './editForm';
// import ServiceListItem from '../../../components/ServiceListItem';

import CurrencyFormat from 'react-currency-format';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const ServiceItem = ({ service, auth, ...rest }) => {
	const [s, setService] = useState(service);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setService({ id, price, tax, name, active, address, cans, type, frequency, notes, datestart, dateend, preferred });
	}, []);

	const updateService = (serv) => {
		setLoading(true);
		new customerService()
			.updateCustomerService(serv, auth.user.token)
			.then((json) => {
				if (json && json.success) setService({ ...s, ...serv });
			})
			.catch((e) => console.log(e))
			.finally((e) => setLoading(false));
	};

	const inActive = {
		borderLeft: '3px solid red',
	};

	const { id, price, tax, name, active, address, cans, type, frequency, notes, datestart, dateend, preferred } = s;

	const tryToParse = (n) => {
		try {
			return JSON.parse(n);
		} catch (e) {
			return n;
		}
	};

	const placementContact = tryToParse(notes);

	return (
		<li>
			<div className="collapsible-header" style={active ? {} : inActive}>
				<i className="material-icons">map</i>
				<span style={{ marginRight: '10px' }}>
					<b>{type} Service : </b>
					{name}
					<br />
					<b>For : </b>
					{address}
					{type === 'Roll-Offs' && (
						<>
							<br />
							<b>Notes: </b>
							{notes}
							<br />
							<b>Dates : </b> {datestart.replace(' 00:00:00 +0000', '')} - {dateend.replace(' 00:00:00 +0000', '')}
						</>
					)}
					{type.toLowerCase() === 'commercial' && (
						<>
							<br />
							<b>Placement Contact Name: </b> {placementContact.fullname}
							<br />
							<b>Placement Contact Email: </b> {placementContact.email}
							<br />
							<b>Placement Contact Phone: </b> {placementContact.phone}
							<br />
							<b>Placement Contact Notes: </b> {placementContact.notes}
						</>
					)}
				</span>
				<span className="right-align badge black-text" style={{ textAlign: 'right' }}>
					<b>Value : </b>
					<CurrencyFormat {...currencyAttr} value={round(price * (1 + tax / 100), 2)} />
					{type !== 'Roll-Offs' && (
						<>
							<br />
							<b>Cans: </b>
							{cans} Cans {frequency} {preferred && preferred.length > 0 && <>: [{preferred && preferred.join(', ')}]</>}
						</>
					)}
				</span>
			</div>
			<div className="collapsible-body">
				{loading && (
					<div className="progress">
						<div className="indeterminate"></div>
					</div>
				)}
				{active === 1 && !loading && (
					<button type="button" className="btn btn-small red white-text" onClick={() => updateService({ id: id, active: 0 })}>
						Deactivate Service
					</button>
				)}
				{active === 0 && !loading && (
					<button type="button" className="btn btn-small green white-text" onClick={() => updateService({ id: id, active: 1 })}>
						Enable Service
					</button>
				)}
			</div>
		</li>
	);
};

const Edit = (props) => {
	const [data, setData] = useState(null);
	const [services, setServices] = useState([]);
	const [fetching, setFetching] = useState(true);
	const { u, auth } = props;

	useEffect(() => {
		let rendered = true;

		function getCustomer() {
			if (!rendered) return;

			setFetching(true);
			new customerService()
				.getCustomer(u, auth.user.token)
				.then((json) => {
					if (json) {
						new customerService()
							.getServices(u, auth.user.token)
							.then((jsonServices) => {
								if (jsonServices) setServices(jsonServices);
							})
							.catch((e) => {});
						setData(json);
					}
				})
				.catch((e) => {})
				.finally(() => setFetching(false));
		}

		getCustomer();

		return () => (rendered = false);
	}, [auth.user.token, u]);

	return !fetching ? (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Customer edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/customers">Customers</Link>
								</li>
								<li className="breadcrumb-item active">Customer Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<ul className="tabs mb-2 row">
									<li className="tab">
										<a className="display-flex align-items-center active" id="account-tab" href="#account">
											<i className="material-icons mr-1">person_outline</i>
											<span>Account</span>
										</a>
									</li>
									<li className="tab active">
										<a className="display-flex align-items-center" id="services-tab" href="#services">
											<i className="material-icons mr-2">error_outline</i>
											<span>Services</span>
										</a>
									</li>
								</ul>
								<div className="divider mb-3"></div>
								<div className="row">
									<div className="col s12" id="account">
										<EditForm initialForm={data} {...props} uuid={services.length && services[0].uuid} status={services.length && services[0].status} />
									</div>
									<div className="col s12" id="services">
										<form id="infotabForm">
											<div className="row">
												<ul className="collapsible">
													{services.map((service, i) => (
														<ServiceItem key={i} service={service} auth={auth} />
													))}
												</ul>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-overlay"></div>
			</div>
		</React.Fragment>
	) : (
		<Loading />
	);
};

export default Edit;
